* {
  margin: 0;
  padding: 0;
}
body {
  font-family: sans-serif;
}
.font-chinoise {
  font-family: "Gang of Three", sans-serif;
}
.font {
  font-family: "Roboto Mono";
}
.gradientColorButton {
  font-family: sans-serif;
  background: linear-gradient(
      91deg,
      #f00 3.12%,
      rgba(254, 149, 57, 0) 73.34%,
      rgba(248, 47, 253, 0) 96.13%
    ),
    linear-gradient(
      91deg,
      #f00 3.12%,
      rgba(254, 149, 57, 0.47) 28.23%,
      rgba(255, 245, 0, 0.32) 54.74%,
      rgba(251, 164, 84, 0.28) 71.95%,
      rgba(248, 47, 253, 0.5) 96.13%
    ),
    #de3400;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/* Changement du padding des flèches   */
.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

/* Changement de taille des flèches  */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 50px;
  height: 50px;
}
.logo {
  background-image: url("../assets/enib.png");
  width: 600px;
  height: 600px;
}
/* .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4 10L13 19L14.4 17.5L7 10L14.4 2.5L13 1L4 10Z' fill='white'/></svg>");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6.99998 1L5.59998 2.5L13 10L5.59998 17.5L6.99998 19L16 10L6.99998 1Z' fill='white'/></svg>");
} */

.first-slide .image-background {
  /* Remplace 'url("chemin/vers/ton/image.jpg")' par le chemin réel de ton image */
  background-image: url("../assets/Home\ v2.png");
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1; /* Ajuste l'opacité selon tes préférences */
  z-index: 1;
  /* filter: blur(5px); */
}
.testColors {
  background-color: rgba(0, 0, 0, 0.6);
}
/* .logoImg {
  background-image: url("../assets/enib-removebgHD.png");
  background-repeat: no-repeat;
  width: 685px;
  height: 535px;
} */
.logoImg {
  background-image: url("../assets/enib-removebgHD.png");
  background-repeat: no-repeat;
  background-size: contain; /* Assurez-vous que l'image est contenue dans le conteneur */
  background-position: center; /* Centrez l'image dans le conteneur */
  width: 100%; /* Utilisez 100% de la largeur du conteneur parent */
  height: auto; /* Ajustez automatiquement la hauteur pour conserver les proportions de l'image */
  min-width: 685px; /* Définissez une largeur maximale pour l'image */
  min-height: 535px; /* Définissez une hauteur maximale pour l'image */
}

.container-info {
  width: 100%;
  height: 700px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.container-info-dev {
  width: 100%;
  height: 300px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.containers:first-child {
  padding-left: 200px;
}
.containers:last-child {
  padding-right: 200px;
}

.white-bar {
  margin: 70px;
  height: 630px;
  width: 5px;
}
.glassmorphism {
  background: rgba(251, 207, 232, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.27);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.neumorphism {
  border-radius: 50px;
  background: linear-gradient(145deg, #dbeafe, #bfdbfe);
  box-shadow: 20px 20px 60px #d5b0c5, -20px -20px 60px rgba(31, 38, 135, 0.27);
}
.flat {
  margin: auto;
  height: 18vw;
  width: 15vw;
  border-radius: 15px;
  background: #dbeafe;
  box-shadow: -5px -5px 20px #ffffffa8, 10px 10px 30px rgba(31, 38, 135, 0.27);
}
