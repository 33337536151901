.btn-grad {
  background-image: linear-gradient(
    to right,
    #4b6cb7 0%,
    #182848 51%,
    #4b6cb7 100%
  );
  /* margin: 10px; */
  padding: 10px 70px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.btn-grad:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}
