.grid-container {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 130px;
  grid-row-gap: 50px;
  margin-bottom: 50px;
}
.profil-photo {
  width: 13vw;
  height: 11vw;
  margin: 20px 20px 10px 20px;
  object-fit: cover;
}
.flatArtist {
  padding: 1vh;
  width: 20vw;
  border-radius: 15px;
  background: #dbeafe;
  box-shadow: -5px -5px 20px #ffffffa8, 10px 10px 30px rgba(31, 38, 135, 0.27);
}
